import React, { useEffect, useRef } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const ContactPageInfoMap = () => {
  const mapStyles = {
    height: "350px",
    width: "100%"
  };

  const defaultCenter = {
    lat: 52.1876752137446,
    lng: 20.966743539872745
  };

  const hynkaLocation = {
    lat: 52.1876752137446,
    lng: 20.966743539872745
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCZEh2osEsPPhV60Kp0BHRm5nDYSrqvylA',
    libraries: ['places'],
  });
  const mapRef = useRef(null);

  useEffect(() => {
    if (isLoaded && mapRef.current) {
      const map = mapRef.current;

      const createMarkers = () => {
        new window.google.maps.Marker({
          position: defaultCenter,
          map,
          title: 'Default Center'
        });

        new window.google.maps.Marker({
          position: hynkaLocation,
          map,
          title: 'Hynka 2a'
        });
      };

      createMarkers();
    }
  }, [isLoaded]);

  return (
    <div className="grid-page-2-sub">
      <div className="contactpage-info-map">
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={15}
            center={defaultCenter}
            onLoad={map => (mapRef.current = map)}
          />
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
};

export default ContactPageInfoMap;
