async function LogAction(action) {
  const url = 'https://script.google.com/macros/s/AKfycbwtB5T1xPhr2nKokLX4OHAvJhMzg2tWf7AfJ7_9-PPy3CDdmWRZWFEzyN0Z8MzUhYugdg/exec'; // wklej tutaj URL WebApp

  // Pobierz adres IP użytkownika
  const ipResponse = await fetch('https://api.ipify.org?format=json');
  const ipData = await ipResponse.json();

  // Pobierz położenie geograficzne użytkownika na podstawie adresu IP
  const locationResponse = await fetch(`https://ipapi.co/${ipData.ip}/json/`);
  const locationData = await locationResponse.json();

  // Pobierz informacje o przeglądarce i urządzeniu użytkownika
  const screenResolution = `${window.screen.width}x${window.screen.height}`;
  const userAgent = navigator.userAgent;

  // Pobierz referrer
  const referrer = document.referrer;

  const data = {
    timestamp: new Date().toISOString(),
    action: action,
    ip: ipData.ip,
    location: {
      city: locationData.city,
      region: locationData.region,
      country: locationData.country_name,
      latitude: locationData.latitude,
      longitude: locationData.longitude,
    },
    screenResolution: screenResolution,
    userAgent: userAgent,
    referrer: referrer,
  };

  fetch(url, {
    method: 'POST',
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  .then(response => {

  })
  .catch(error => {

  });
}

export default LogAction;
